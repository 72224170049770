import React from 'react';
import PropTypes from 'prop-types';
import {
  ScrollView, StyleSheet, Text, View, Image,
} from 'react-native';
import { withLocalize } from 'react-localize-redux';

import companyAutoCreateValidation from './CompanyAutoCreateValidation';
import { colors, fontFamily, fontSizes } from '../../../common/styles/base.style';
import FormikWithValidation from '../../../common/components/form/FormikWithValidation';
import GeneralFormError from '../../../common/components/form/GeneralFormError';
import FormikFieldPrimarySwitch from '../../../common/components/form/switch/FormikFieldPrimarySwitch';
import Wrapper from '../../../common/components/Wrapper';
import SignupDocumentModal from '../../../common/screens/SignupDocumentModal';
import { getTermsAndPrivacySwitchLabel } from '../../../common/screens/termsAndConditionSwitchLabel';
import { Button } from '../../../common/components';
import { BUTTON_TYPES } from '../../../common/components/buttons/Button';
import PrimarySwitchBolek from '../../../common/components/form/switch/PrimarySwitchBolek';
import ViewportController from '../../../common/components/ViewportController';
import { font } from '../../../common/styles/mixins';

const welcomeImage = require('../../../assets/photos/autoCreate/welcome.png');

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    marginVertical: 'auto',
    height: '80%',
  },
  welcomeImage: {
    height: '100%',
  },
  bottomWrapper: {
    alignItems: 'center',
    height: '100%',
    justifyContent: 'space-evenly',
  },
  text: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.lg),
  },
});

const TAndCModal = ({
  termsText, termsModalVisible, handleCloseModal, translate,
}) => (
  <SignupDocumentModal
    visible={termsModalVisible}
    handleCloseModal={handleCloseModal}
    header={translate('companyWelcomeScreen.signupScreen.termsHeader')}
  >
    <ScrollView>
      <Text>{termsText}</Text>
    </ScrollView>
  </SignupDocumentModal>
);

const PrivacyModal = ({
  privacyText, privacyModalVisible, handleCloseModal, translate,
}) => (
  <SignupDocumentModal
    visible={privacyModalVisible}
    handleCloseModal={handleCloseModal}
    header={translate('companyWelcomeScreen.signupScreen.privacyHeader')}
  >
    <ScrollView>
      <Text>{privacyText}</Text>
    </ScrollView>
  </SignupDocumentModal>
);

const GetAccessToCandidatesForm = ({
  initialValues, onSubmit, onOpenPrivacy, onOpenTerms, translate,
}) => (
  <FormikWithValidation
    validation={companyAutoCreateValidation}
    initialValues={initialValues}
    onSubmit={onSubmit}
    render={({ handleSubmit, isBtnDisabled, errors }) => (
      <Wrapper style={styles.bottomWrapper}>
        <FormikFieldPrimarySwitch Switch={PrimarySwitchBolek} name="termsAndPrivacy">
          {getTermsAndPrivacySwitchLabel((key) => translate(`companyWelcomeScreen.signupScreen.${key}`), onOpenPrivacy, onOpenTerms, styles.text)}
        </FormikFieldPrimarySwitch>
        <View>
          <Button
            style={{ wrapper: { minWidth: 0 } }}
            type={BUTTON_TYPES.PRIMARY_RED}
            title={translate('company.auth.autoCreateScreen.signupButton')}
            onPress={handleSubmit}
            disabled={isBtnDisabled}
          />
          <GeneralFormError errors={errors} />
        </View>
      </Wrapper>
    )}
  />
);

const CompanyAutoCreateScreen = (props) => (
  <ViewportController statusBarHidden>
    <TAndCModal {...props} />
    <PrivacyModal {...props} />

    <View style={styles.container}>
      <View style={{ height: '60%' }}>
        <Image source={welcomeImage} style={styles.welcomeImage} fadeDuration={0} resizeMode="contain" />
      </View>
      <View style={{ height: '40%' }}>
        <GetAccessToCandidatesForm {...props} />
      </View>
    </View>
  </ViewportController>
);

TAndCModal.propTypes = {
  termsText: PropTypes.string,
  termsModalVisible: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};
TAndCModal.defaultProps = {
  termsText: '',
};

PrivacyModal.propTypes = {
  privacyText: PropTypes.string,
  privacyModalVisible: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};
PrivacyModal.defaultProps = {
  privacyText: '',
};

GetAccessToCandidatesForm.propTypes = {
  initialValues: PropTypes.shape({
    termsAndPrivacy: PropTypes.bool.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onOpenPrivacy: PropTypes.func.isRequired,
  onOpenTerms: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

CompanyAutoCreateScreen.propTypes = {
  ...GetAccessToCandidatesForm.propTypes,
  ...TAndCModal.propTypes,
  ...PrivacyModal.propTypes,
};
CompanyAutoCreateScreen.defaultProps = {
  ...TAndCModal.defaultProps,
  ...PrivacyModal.defaultProps,
};

export default withLocalize(CompanyAutoCreateScreen);
