import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import Container from '../components/Container';
import * as propTypes from '../../constants/propTypes';
import BackgroundImageGradient from './BackgroundImageGradient';
import LogoComponent, { LogoImages } from '../components/LogoComponent';

const styles = StyleSheet.create({
  image: {
    flex: 1,
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
  },
  container: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  logos: {
    position: 'absolute',
    bottom: -60,
    left: 0,
    marginLeft: 19,
    marginBottom: '15%',
  },
});

const webImgSrc = require('../../assets/photos/welcomeCompany/companyWelcomeWeb.jpg');

const CompanyBackgroundImageScreen = ({
  children, containerHeight, style,
}) => (
  <Container>
    <View source={webImgSrc} imageStyle={styles.img} style={[{ backgroundImage: `url(${webImgSrc})` }, styles.image, style.image]}>
      <BackgroundImageGradient />
    </View>
    <View style={[styles.container, { height: containerHeight }, style.content]}>
      {children}
    </View>
  </Container>
);

CompanyBackgroundImageScreen.propTypes = {
  children: PropTypes.node.isRequired,
  containerHeight: PropTypes.number,
  style: PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    image: propTypes.imagePropType,
  }),
};

CompanyBackgroundImageScreen.defaultProps = {
  containerHeight: 274,
  style: {
    content: null,
    image: {},
  },
};

export default CompanyBackgroundImageScreen;
