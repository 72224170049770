import React from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet, View, ImageBackground,
} from 'react-native';
import Container from '../components/Container';
import LogoComponent, { LogoImages } from '../components/LogoComponent';
import ViewportController from '../components/ViewportController';
import * as propTypes from '../../constants/propTypes';

const styles = StyleSheet.create({
  image: {
    flex: 1,
    width: '100%',
  },
  container: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  lgLogoContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  lgLogo: {
    position: 'absolute',
    top: 0,
    right: 0,
    marginRight: 19,
    marginTop: 0,
  },
});

// hack for android and KeyboardAwareScrollView plugin. if the container has flex 1, it shrinks and the view is no longer scrollable.
// that's why we explicitely set the height after it's calculated
let imageBackgroundHeight;
const onLayout = (event) => {
  const { height: calculatedHeight } = event.nativeEvent.layout;
  imageBackgroundHeight = calculatedHeight;
};

const BackgroundImageScreen = ({
  children, img, containerHeight, style,
}) => (
  <Container>
    <ImageBackground source={img} style={[styles.image, style.image, { height: imageBackgroundHeight }]} fadeDuration={0} onLayout={onLayout}>
      <ViewportController safeAreaTop style={styles.lgLogoContainer}>
        <Container>
          <LogoComponent style={styles.lgLogo} logo={LogoImages.LG_LOGO_WHITE} />
        </Container>
      </ViewportController>
      {/* <BackgroundImageGradient /> */}
    </ImageBackground>
    <View style={[styles.container, { height: containerHeight }, style.content]}>
      {children}
    </View>
  </Container>
);

BackgroundImageScreen.propTypes = {
  children: PropTypes.node.isRequired,
  img: propTypes.imagePropType.isRequired,
  containerHeight: PropTypes.number,
  style: PropTypes.shape({
    content: propTypes.stylePropTypes,
    image: propTypes.stylePropTypes,
  }),
};

BackgroundImageScreen.defaultProps = {
  containerHeight: 274,
  style: {
    content: null,
    image: {},
  },
};

export default BackgroundImageScreen;
