import React from 'react';
import PropTypes from 'prop-types';
import ImageComponent from './image/ImageComponent';
import * as propTypes from '../../constants/propTypes';

const lgLogo = require('../../assets/logo/lg_logo.png');
const lgLogoWhite = require('../../assets/logo/lg_logo_white.png');

export const LogoImages = {
  LG_LOGO: {
    imgSrc: lgLogo,
    width: 196,
    height: 138,
  },
  LG_LOGO_WHITE: {
    imgSrc: lgLogoWhite,
    width: 133,
    height: 90,
  },
};

const LogoComponent = ({ style, logo }) => (
  <ImageComponent image={logo.imgSrc} style={{ image: { width: logo.width, height: logo.height, resizeMode: 'contain' }, wrapper: style }} />
);

LogoComponent.propTypes = {
  style: propTypes.stylePropTypes,
  logo: PropTypes.shape({
    imgSrc: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};

LogoComponent.defaultProps = {
  style: {},
  logo: {
    imgSrc: lgLogo,
    width: 196,
    height: 138,
  },
};

export default LogoComponent;
